import {useContext} from "react";
import {isEmpty} from "~/utils";
import {AppContext} from "~/context/AppProvider";
import OrderPrintItem from "./OrderPrintItem";
import OrderPrintSpx from "./OrderPrintSpx";
function OrderPrintList({items, printHidden}) {
	const {country, shippingCompanies} = useContext(AppContext);
	const DeliveryInstructions = ({item}) => {
		return (
			<>
				<p style={{lineHeight:'10px', fontSize:'10px', marginBottom:'5px', fontStyle:'italic'}}>
					{(item.requiredNote === 'KHONGCHOXEMHANG') && 'Không cho xem hàng. '}
					{(item.requiredNote === 'CHOXEMHANGKHONGTHU') && 'Cho xem hàng không thử. '}
					{(item.requiredNote === 'CHOTHUHANG') && 'Cho xem hàng. '}
					{(!isEmpty(item.note)) && item.note}
				</p>
				{
					Object.entries(item.items).map(([index, pr], i) => {
						return (<p style={{lineHeight:'10px', fontSize:'10px'}}>{pr.title} (SL: {pr.quantity})</p>)
					})
				}
			</>
		)
	};
	return Object.entries(items).map(([index, item], i) => {
		if(item?.shippingUnit === 'spx')
			return (<OrderPrintSpx key={index} item={item} shippingCompanies={shippingCompanies} DeliveryInstructions={DeliveryInstructions} printHidden={printHidden} country={country}/>)
		else
			return (<OrderPrintItem key={index} item={item} shippingCompanies={shippingCompanies} DeliveryInstructions={DeliveryInstructions} printHidden={printHidden} country={country}/>)
	})
}
export default OrderPrintList;